.style {
    width: 100%;
    height: 100vh;
    background: linear-gradient(
          -45deg,
          #292dc2 0%,
          rgb(120, 105, 255) 100%
      );
    display: flex;
    justify-content: center;
    align-items: center;
  }
.form-outline{
    position: relative;
    width: 380px;
    height: 450px;
    background-color: #1c1c1c;
    overflow: hidden;
}
.form-outline::before{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent,#9fa2f0,#9fa2f0);
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
}
.form-outline::after{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent,#9fa2f0,#9fa2f0);
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -3s;
}
@keyframes animate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
/* Form */
.form{
    position: absolute;
    inset: 2px;
    border-radius: 8px;
    background: #28292d;
    z-index: 10;
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
}
.form h2{
    color: #9fa2f0;
    font-weight: 500;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
}
.inputBox{
    position: relative;
}
.inputBox input{
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    border: none;
    outline: none;
    color: #23242a;
    font-size: 1em;
    letter-spacing: 0.05em;
    z-index: 10;
}
.inputBox span{
    position: absolute;
    left: 0;
    padding: 20px 0px 10px;
    font-size: 1em;
    color: #8f8f8f;
    pointer-events: none;
    letter-spacing: 0.05em;
    transition: 0.5s;
}
.inputBox input:valid ~ span,
.inputBox input:focus ~ span{
    color: #9fa2f0;
    transform: translateX(0px) translateY(-34px);
    font-size: 0.75em;
}
.inputBox i{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #9fa2f0;
    border-radius: 4px;
    transition: 0.5s;
    pointer-events: none;
    z-index: 0;
}
.inputBox input:valid ~ i,
.inputBox input:focus ~ i{
     height: 44px;
}
.btn-default {
    background: #9fa2f0;
    width: 100px;
    font-weight: 600;
    cursor: pointer;
} 
.btn-default:active{
    opacity: 0.8;
}