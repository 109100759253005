.body {
  width: 100wh;
  /* counter-reset: Serial;    */
  background: linear-gradient(
		-45deg,
		#f9f9fb 0%,
		rgb(245, 245, 247) 100%
	);
}    
  .cell-1 {
    border-collapse: separate;
    border-spacing: 0 4em;
    background: #ffffff;
    border-bottom: 5px solid transparent;
    /*background-color: gold;*/
    background-clip: padding-box;
    cursor: pointer;
    text-align: left;
    font-size: 12px;
  }
  .row-child {
    background-color: #F2F9F9;
    color: #000;
  }
  .radius {
    border-radius: 18px;
  }

  .button.button-small {
    height: 30px;
    line-height: 30px;
    padding: 0px 10px;
  }
.rdrDateRangePickerWrapper {
  justify-content: end;
}
.fapencil {
  color: blue;
}
.fatrash {
  color: red;
}